import { createAsyncThunk } from '@reduxjs/toolkit';

import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getGroup } from '@wix/ambassador-social-groups-v1-group/http';
import { queryGroups } from '@wix/ambassador-social-groups-v2-group/http';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  function (groupId: string, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;

    return httpClient.request(getGroup({ groupId }));
  },
);

export const determine = createAsyncThunk(
  'group:determine',
  async function (_, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;

    const {
      data: { groups },
    } = await httpClient.request(
      queryGroups({
        query: {
          filter: {
            privacyLevel: PrivacyStatus.PUBLIC,
          },
        },
      }),
    );

    const group = groups?.[0];

    return group?.id;
  },
);
