import { createSlice } from '@reduxjs/toolkit';

import { fetchGroup } from './actions';
import { IGroupState } from './types';

export const initialState: IGroupState = {
  loading: false,
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchGroup.pending, function (state) {
      state.loading = true;
    });

    builder.addCase(fetchGroup.fulfilled, function (state, action) {
      return {
        ...action.payload.data.group,
        loading: false,
      };
    });
  },
});
