import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import {
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';
import { pick, omit } from 'lodash';

import { COMPONENT_ID } from 'common/utils/utils';

import settingsParams from './settingsParams';
import { createStore } from './store';
import { groupActions } from './store/group';
import { feedSelectors } from './store/feed/adapter';
import { feedActions } from './store/feed';
import { applicationActions } from './store/application';

const createController: CreateControllerFn = async (controllerContext) => {
  const { flowAPI } = controllerContext;
  const { wixCodeApi, setProps, appParams } = flowAPI.controllerConfig;

  let groupId = flowAPI.settings.get(settingsParams.groupId);
  const postsCount = flowAPI.settings.get(settingsParams.postsCount);

  const { isSSR } = flowAPI.environment;
  const { warmupData } = wixCodeApi.window;

  const store = createStore(controllerContext);

  return {
    async pageReady() {
      // if in SSR or in local env (SSR is disabled)
      if (isSSR || !warmupData.get('FeedWidget_store')) {
        if (!groupId) {
          groupId = (await determineGroup().unwrap()) as string;
        }

        if (groupId) {
          await Promise.all([
            await fetchGroup(groupId),
            await fetch(groupId, postsCount),
          ]);
        }
      }

      const state = store.getState();

      if (isSSR) {
        warmupData.set('FeedWidget_store', omit(state, ['application']));
      }

      setProps({
        store: state,
        group: {
          fetchGroup,
          redirectToGroup,
        },
        feed: {
          pin,
          unpin,
          fetch,
          react,
          unreact,
          subscribe,
          unsubscribe,
          fetchMore,
        },
      });

      store.subscribe(() => {
        setProps({
          store: store.getState(),
        });
      });

      wixCodeApi.user.onLogin(handleUserLogin);

      const commentsApi = await initializeCommentsController(
        flowAPI.controllerConfig,
        {
          appDefinitionId: appParams.appDefinitionId,
          httpClient: flowAPI.httpClient,
        },
      );

      commentsApi.watch.pagination.onChange(handleCommentsChange);
    },
  };

  async function redirectToGroup(searchParams?: string[][]) {
    const { url } = await wixCodeApi.site.getSectionUrl({
      appDefinitionId: appParams.appDefinitionId,
      sectionId: COMPONENT_ID.GROUP,
    });

    const params = new URLSearchParams(searchParams);

    wixCodeApi.location.to?.(`${url}/${groupId}?${params.toString()}`);
  }

  function fetchGroup(id: string) {
    return store.dispatch(groupActions.fetchGroup(id));
  }

  function determineGroup() {
    return store.dispatch(groupActions.determine());
  }

  function handleUserLogin() {
    store.dispatch(
      applicationActions.login({
        instance: wixCodeApi.site.getAppToken!(appParams.appDefinitionId),
        user: wixCodeApi.user.currentUser,
      }),
    );
    fetchGroup(groupId);
    fetch(groupId);
  }

  function subscribe(feedItemId: string) {
    return store.dispatch(feedActions.subscribe(feedItemId));
  }

  function unsubscribe(feedItemId: string) {
    return store.dispatch(feedActions.unsubscribe(feedItemId));
  }

  async function pin(feedItemId: string) {
    const { feed } = store.getState();

    const pinned = feedSelectors.selectAll(feed).find((item) => !!item.pin);

    if (pinned) {
      await unpin(pinned.feedItemId!);
    }

    return store.dispatch(feedActions.pin(feedItemId));
  }

  function unpin(feedItemId: string) {
    return store.dispatch(feedActions.unpin(feedItemId));
  }

  function react(feedItemId: string, code: string) {
    return store.dispatch(feedActions.react({ feedItemId, code }));
  }

  function unreact(feedItemId: string, code: string) {
    return store.dispatch(feedActions.unreact({ feedItemId, code }));
  }

  function fetch(id: string, limit: number = 10) {
    return store.dispatch(feedActions.fetch({ groupId: id, limit }));
  }

  function fetchMore(limit: number = 10) {
    return store.dispatch(feedActions.fetchNext(limit));
  }

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feedActions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }
};

export default createController;
